@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border;
}
html {
  @apply scroll-smooth;
}
body {
  @apply m-0 p-0 font-primary text-lg text-black;
}
a {
  @apply hover:underline;
}
.linear-text {
  @apply bg-clip-text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

/* Buttons */
.primary-button,
.whatsapp-button,
.white-button,
.black-button,
.green-button,
.form-control {
  @apply focus:outline-none text-md rounded-lg align-middle font-aton hover:no-underline uppercase font-normal relative;
}
.whatsapp-button {
  @apply text-white px-[32.5px] py-[16.5px] bg-[#4ECA5C] shadow-button-whatsapp m-[1px];
}
.primary-button {
  @apply text-white px-[32.5px] py-[16.5px] bg-primary shadow-button-orange m-[1px];
}
.green-button {
  @apply text-white px-[32.5px] py-[16.5px] bg-green shadow-button-green m-[1px];
}
.primary-button.rounded-left::after,
.greenn-button.rounded-left::after,
.black-button.rounded-left::after {
  border-radius: 9px 0 0 9px !important;
}
.primary-button.rounded-right::after,
.green-button.rounded-right::after,
.black-button.rounded-right::after {
  border-radius: 0 9px 9px 0 !important;
}
.white-button,
.form-control {
  @apply text-black border border-gray-300 bg-white shadow-button-white px-[18px] py-4;
}
.black-button {
  @apply text-white px-[32.5px] py-[16.5px] bg-black shadow-button-black m-[1px];
}
.primary-button::after,
.whatsapp-button::after,
.green-button::after,
.black-button::after {
  --width: -1px;
  @apply content-[""] absolute rounded-[9px];
  top: var(--width);
  right: var(--width);
  left: var(--width);
  bottom: var(--width);
  z-index: -1;
}

.primary-button::after {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.45) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.green-button::after {
  background-image: linear-gradient(#b5dbd7, transparent);
}
.black-button::after {
  background-image: linear-gradient(#b5b7bb, #131b25);
}
.form-control {
  @apply font-primary placeholder:text-black text-black capitalize;
}

/* End Buttons */

.react-multi-carousel-list.review-container {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.react-multi-carousel-dot-list.review-navigation {
  bottom: -100px !important;
  position: unset;
}
.react-multi-carousel-track {
  align-self: flex-start;
}
.react-multiple-carousel__arrow {
  @apply invisible md:visible;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  @apply bg-black bg-opacity-5 w-[52px] h-[52px] before:text-black before:content-[""] before:bg-arrow-right before:w-6 before:h-6 flex justify-center items-center -translate-y-1/2 top-1/2;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  @apply rotate-180;
}
.react-multiple-carousel__arrow:hover {
  @apply bg-black bg-opacity-25 !important;
}
.react-multi-carousel-dot-list.review-navigation {
  @apply flex gap-3 mt-20;
}
.react-multi-carousel-dot-list.review-navigation .react-multi-carousel-dot button {
  @apply border-0 w-3 bg-black bg-opacity-30;
}
.react-multi-carousel-dot-list.review-navigation
  .react-multi-carousel-dot.react-multi-carousel-dot--active
  button {
  @apply bg-black;
}

@media screen and (max-width: 768px) {
  .px-nmd-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
